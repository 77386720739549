:root {
  --main-color: #448ced;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: none;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* OVERRIDED STYLES */

.ui.form .field > label {
  color: #40444a !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.ui.form .field.error > label {
  color: #9f3a38 !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
  min-width: 150px;
  font-size: 15px !important;
  height: 45px;
  border-radius: 3px !important;
  margin-right: 0;
}

.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
  border: 1px solid #adb1b9 !important;
  border-radius: 3px !important;
  height: 40px;
}

.ui.selection.dropdown {
  border: 1px solid #adb1b9 !important;
  height: 40px;
}

.ui.form input:not([type]):focus,
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='url']:focus {
  border: 1px solid #448ced !important;
  border: 1px solid var(--main-color) !important;
}

.ui.selection.dropdown.active,
.ui.selection.dropdown:focus {
  border: 1px solid #448ced !important;
  border: 1px solid var(--main-color) !important;
}

.ui.selection.active.dropdown .menu {
  border-color: #448ced !important;
  border-color: var(--main-color) !important;
}

.ui.form .field {
  margin-bottom: 12px !important;
}

table.ui.striped.table tbody tr:nth-child(odd),
table.ui.striped.table > tr:nth-child(odd) {
  background-color: #f4f5f7;
}

table.ui.striped.table tbody tr:nth-child(even),
table.ui.striped.table > tr:nth-child(even) {
  background-color: transparent;
}

.ui-alerts {
  max-width: 400px;
}

